export const validateTasklistInputData = (input) => {
  if (input.taskListItem.taskListId === '') {
    return 'Please Select Tasklist'
  } else if (input.taskListItem.displayColumns.length === 0) {
    return 'Please Select Columns to be mapped with Tasklist'
  } else if (input.filterValueIds.length === 0) {
    return 'Please select Filters for the Tasklist'
  } else if (input.filterValueIds.includes('UNDEFINED')) {
    return 'Please assign values to all selected filters'
  }
  return 'IsValid'
}

export const simplifyTasklistData = (TaskListRawData) => {
  const returnList = []
  TaskListRawData.forEach(task => {
    const taskListObject = {}
    taskListObject.id = task.id
    taskListObject.name = task.name
    taskListObject.description = task.description
    taskListObject.displayOrder = task.displayOrder
    taskListObject.filterNames = []
    task.filters.forEach(filter => {
      taskListObject.filterNames.push(filter.filterName)
    })
    returnList.push(taskListObject)
  })
  return returnList
}

export const TaskListHeaders = [
  { text: 'Task List Name', value: 'name', sortable: true, width: '20%' },
  { text: 'Description', value: 'description', sortable: false, width: '30%' },
  { text: 'Order', value: 'displayOrder', sortable: true },
  { text: 'Active Filters', value: 'filterNames', sortable: false },
  { text: 'Action', value: 'Edit', sortable: false }
]
