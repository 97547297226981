<template>
  <v-app>
    <v-container class="outerContainer">
      <div class="pageHeading"> Task Lists
        <v-tooltip bottom content-class="toolTip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ma-2" color="#A0A3BD" v-bind="attrs" v-on="on" @click="showDocumentation()"> mdi-information </v-icon>
          </template>
          <span> Click to go to Master list of Task lists </span>
        </v-tooltip>
      </div>
      <v-container class="tasklistContainer">
        <div class="d-flex paddingClass">
          <span class="deploymentDropdown">
            <v-label>Select Deployment</v-label>
            <span v-if="deploymentCodesServerBased.length > 0">
              <v-autocomplete v-model="DeploymentCode" v-on:change="getTaskListForDeployment()" :items="deploymentCodesServerBased" placeholder="Select"></v-autocomplete>
            </span>
            <span v-else>
              <v-autocomplete v-model="DeploymentCode" :items="deploymentCodesServerBased" placeholder="Select" loading></v-autocomplete>
            </span>
          </span>
          <div class="addNewTaskListButton">
            <Button label="Add New Tasklist" class="buttonInnerPadding" v-on:click="addNewTasklist()" :disabled="DeploymentCode === ''" v-if="!TasklistDetailingComp"/>
          </div>
        </div>
        <v-divider></v-divider>
        <div v-if="!TasklistDetailingComp">
          <div v-if="DeploymentViseTaskList.length > 0">
            <v-data-table :headers="TaskListTableHeaders" :items="DeploymentViseTaskList" height="440px" :fixed-header="true"  :items-per-page="7" class="tasklistTableContent">
              <template v-slot:item.filterNames="{ item }">
                <v-tooltip bottom content-class="toolTip">
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="getClassForFilters(item.filterNames.length)" v-bind="attrs" v-on="on"> {{ getTruncatedList(item.filterNames) }} </div>
                  </template>
                  {{ item.filterNames.toString() }}
                </v-tooltip>
              </template>
              <template v-slot:item.Edit="{ item }">
                <v-btn icon v-on:click="editTasklist(item)"><v-icon color="#1155CC">mdi-pencil</v-icon></v-btn>
                <v-btn icon v-on:click="openConfigmationDialogBeforeDelete(item.id)"><v-icon color="#E2493F">mdi-delete</v-icon></v-btn>
              </template>
            </v-data-table>
          </div>
          <div v-else-if="isLoading">
            <v-data-table height="440px" :fixed-header="true"  :items-per-page="8" class="tasklistTableContent" loading loading-text="Please wait while we fetch TaskLists from server."></v-data-table>
          </div>
          <div v-else class="emptyTaskListCard">
            <div class="emptyTaskList emptyTaskListImg tasklistTableContent">
                <v-img src="../assets/TasklistVector.png" height="45px" width="45px"></v-img>
            </div>
            <span class="emptyTaskList tasklistTableContent"> No data found! You can add a new task list. </span>
          </div>
        </div>
        <div class="emptyTaskListCard detailingCompnentPadding tasklistTableContent" v-else>
          <TaskListDetailingComponent :addNew="AddNew" :EditTasklist="EditTasklistObject" :TasklistDetailingComp.sync="TasklistDetailingComp"/>
        </div>
      </v-container>
    </v-container>
    <div v-if="openDialog">
      <ConfirmationDialog :showConfirmationDialog.sync="openDialog" :item="DeleteTasklistId" :submitFunction="this.deleteTaskListWithId" :confirmationDialogText="confirmationDialogText"/>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '../router/index'
import { initializePlatformMatomo } from '../../utils/matomoTracking'
import { routeNames } from '../../utils/dataValidations'
import { getTaskListForDeploymentCode, deleteTaskList } from '../store/tasklistAPICollection'
import { getSampleFileDriveLink } from '../../utils/utils'
import { TaskListHeaders, simplifyTasklistData } from '../../utils/tasklistConstants'
import TaskListDetailingComponent from './TaskListDetailingComponent.vue'
import Button from './sharedcomponents/Button.vue'
import ConfirmationDialog from './sharedcomponents/Modal.vue'

export default {
  components: { TaskListDetailingComponent, Button, ConfirmationDialog },
  computed: {
    ...mapState([
      'isAuth',
      'isProd',
      'platform',
      'deploymentCodesServerBased'
    ])
  },
  data: () => ({
    DeploymentCode: '',
    DeploymentViseTaskList: [],
    TaskListTableHeaders: [],
    isLoading: false,
    TasklistDetailingComp: false,
    AddNew: false,
    EditTasklistObject: {},
    TaskListRawData: [],
    openDialog: false,
    DeleteTasklistId: 0,
    confirmationDialogText: {
      title: 'Delete Tasklist',
      dialogtext: 'Are you sure you want to delete this tasklist? This will delete all the data associated with this tasklist.',
      dialogCancelText: 'Cancel',
      dialogSubmitText: 'Delete'
    }
  }),
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    this.setDeploymentName(this.$route.query.platform)
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
    this.setDeploymentName(this.$route.query.platform)
    this.setServerBasedDeploymentCodes({ data: null, path: '/v1/deployments' })
    initializePlatformMatomo()
    this.TaskListTableHeaders = TaskListHeaders
  },
  watch: {
    TasklistDetailingComp: function (newValue) {
      if (!newValue) {
        this.getTaskListForDeployment()
      }
    }
  },
  methods: {
    ...mapActions([
      'setDeploymentName',
      'setServerBasedDeploymentCodes',
      'setSelectedDeploymentCode'
    ]),
    editTasklist (tasklistObject) {
      this.EditTasklistObject = this.TaskListRawData.find(e => e.id === tasklistObject.id)
      this.TasklistDetailingComp = true
      this.AddNew = false
    },
    openConfigmationDialogBeforeDelete (id) {
      this.DeleteTasklistId = id
      this.openDialog = true
    },
    async deleteTaskListWithId (id) {
      await deleteTaskList(id)
    },
    async getTaskListForDeployment () {
      this.setSelectedDeploymentCode(this.DeploymentCode)
      this.isLoading = true
      this.DeploymentViseTaskList = []
      this.TaskListRawData = await getTaskListForDeploymentCode(this.DeploymentCode)
      this.DeploymentViseTaskList = await simplifyTasklistData(this.TaskListRawData)
      this.isLoading = false
    },
    addNewTasklist () {
      this.TasklistDetailingComp = true
      this.AddNew = true
    },
    getTruncatedList (filterList) {
      let filterString = ''
      let filterCount = filterList.length
      filterString += filterList[0]
      if (filterCount > 1) {
        filterString += ', ' + filterList[1]
        filterCount = filterCount - 2
        if (filterCount > 0) {
          filterString += ' +' + filterCount
        }
      }
      return filterString
    },
    showDocumentation () {
      const driveLink = getSampleFileDriveLink('Tasklist Master List')
      window.open(driveLink, '_blank')
    },
    getClassForFilters (count) {
      if (count > 2) {
        return 'textHiglight'
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.detailingCompnentPadding {
  padding-top: 2%;
  padding-left: 1.5%;
}
.emptyTaskListCard {
  height: 440px;
  position: relative;
}
.emptyTaskList {
  position: relative;
  height: fit-content;
  width: fit-content;
  top: 35%;
}
.emptyTaskListImg {
  left: 47.5%;
}
.textHiglight {
  border-bottom: 1px dotted #1155CC;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 157%;
  vertical-align: middle;
  letter-spacing: 0.0125em;
  color: rgba(17, 85, 204, 0.75);
}
.toolTip {
  background: #FCFCFC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-size: 12px;
  line-height: 183%;
  letter-spacing: 0.015em;
  color: #1E234C;
  max-width: 500px;
}
.nowrap {
  white-space: nowrap;
  min-width: 250px !important;
  max-width: 250px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasklistTableContent {
  table-layout: fixed;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  text-align: left;
}
.paddingClass {
    padding: 35px;
}
.v-ripple__container {
    display:none !important;
}
.addNewTaskListButton {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: static;
}
.buttonInnerPadding {
  height: 36px;
  padding: 0px 16px;
}
.outerContainer {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: #000000;
  width: 1312px !important;
}
.tasklistContainer {
  position: absolute;
  width: 1312px;
  height: 620px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
  border-radius: 12px;
}
.deploymentDropdown {
  display: flex;
  flex-direction: column;
}
.deploymentDropdown > * {
  position: static !important;
  left: 0px !important;
  font-weight: 400 !important;
  margin: 0px 0px !important;
}
.deploymentDropdown > label {
  width: 100px;
  height: 20px;
  font-size: 12px;
  line-height: 167%;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.deploymentDropdown > span > .v-select {
  width: 200px;
  height: 16px;
  font-size: 16px;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.25);
}
.deploymentDropdown > span > .v-text-field {
  padding-top: 0px;
}
.pageHeading {
    text-align: left;
}
</style>
