<template>
  <v-container class="fillHeight" fluid>
    <v-row class="nonScrollable">
      <v-col cols="2.5">
        <v-label>Select TaskList</v-label>
        <v-autocomplete :items="TasklistNamesRawData" v-model="SelectedName" item-value="id" item-text="name" :disabled="!addNew"/>
      </v-col>
      <v-col cols="2.5">
        <v-label>Display Columns</v-label>
        <v-autocomplete :items="TasklistColumnsRawData" multiple v-model="SelectedColumns" :disabled="SelectedName === ''" item-value="id" item-text="name">
          <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="nowrap">{{ item.name }}</span>
              <span v-if="index === 1"> &nbsp; +{{ SelectedColumns.length - 1 }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2.5">
        <v-label>Preferred Order</v-label>
        <v-text-field height="44px" type="number" v-model="PrefferedOrder" :disabled="SelectedColumns === ''"/>
      </v-col>
      <v-col cols="2.5">
        <v-label>Select Filters</v-label>
        <v-autocomplete :items="TasklistFiltersRawData" multiple v-model="SelectedFilters" :disabled="SelectedColumns === ''" item-value="id" item-text="name">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="nowrap">{{ item.name }}</span>
            <span v-if="index === 1"> &nbsp; +{{ SelectedFilters.length - 1 }}</span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <div class="filterHeading">
      <span v-if="SelectedFilters !== '' && SelectedFilters.length !== 0">Manage Filters</span>
      <span v-else> &nbsp; </span>
    </div>
    <v-row class="scrollable" no-gutters>
      <v-col v-for="filters in SelectedFiltersWithPossibleValues" :key="filters.id" cols="2.5">
        <v-label>{{ filters.name }}</v-label>
        <v-select :items="filters.values" item-text="value" item.value="id" v-model="filters.selectedFilterValue"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="SubmitCancelButton">
        <div>
          <Button label="Cancel" type="outlined" v-on:click="close()"/>
          <Button label="Save Task List" v-on:click="submitChanges()"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >

import { mapState } from 'vuex'
import Button from './sharedcomponents/Button.vue'
import { addTaskList, getAllTaskList, getAllColumns, getAllFilters, updateTaskList } from '../store/tasklistAPICollection'

export default {
  props: {
    addNew: {
      type: Boolean,
      default: true
    },
    EditTasklist: {
      type: Object
    }
  },
  components: { Button },
  computed: {
    ...mapState([
      'selectedDeploymentCode'
    ])
  },
  data: () => ({
    TasklistNamesRawData: [],
    TasklistColumnsRawData: [],
    TasklistFiltersRawData: [],
    SelectedName: '',
    SelectedColumns: '',
    PrefferedOrder: null,
    SelectedFilters: '',
    SelectedFiltersWithPossibleValues: []
  }),
  watch: {
    SelectedFilters: function (upd) {
      this.SelectedFiltersWithPossibleValues = []
      upd.forEach(filter => {
        const filterObj = this.TasklistFiltersRawData.find(e => e.id === filter)
        this.SelectedFiltersWithPossibleValues.push(filterObj)
      })
    }
  },
  beforeMount: function () {
    this.SelectedFiltersWithPossibleValues = []
  },
  mounted: async function () {
    this.TasklistNamesRawData = await getAllTaskList()
    this.TasklistColumnsRawData = await getAllColumns()
    this.TasklistFiltersRawData = await getAllFilters()
    if (this.addNew === false) {
      this.assignExistingValues()
    }
  },
  methods: {
    async submitChanges () {
      const submitObj = {}
      submitObj.taskListItem = {
        deploymentCode: this.selectedDeploymentCode,
        taskListId: this.SelectedName,
        displayColumns: this.SelectedColumns,
        displayOrder: this.PrefferedOrder
      }
      submitObj.filterValueIds = []
      this.SelectedFiltersWithPossibleValues.forEach(element => {
        let foundSelectedFilterValueId = false
        if (element.selectedFilterValue != null) {
          element.values.forEach(possibilites => {
            if (possibilites.value === element.selectedFilterValue) {
              foundSelectedFilterValueId = true
              submitObj.filterValueIds.push(possibilites.id)
            }
          })
        }
        if (foundSelectedFilterValueId === false) {
          submitObj.filterValueIds.push('UNDEFINED')
        }
      })
      var success = false
      if (this.addNew) {
        success = await addTaskList(submitObj)
      } else {
        success = await updateTaskList(submitObj, this.EditTasklist.id)
      }
      if (success === true) {
        this.close()
      }
    },
    assignExistingValues () {
      this.SelectedName = this.TasklistNamesRawData.find(e => e.name === this.EditTasklist.name && e.extraData === this.EditTasklist.extraData).id
      this.SelectedColumns = this.EditTasklist.displayColumns.map(a => a.id)
      this.PrefferedOrder = this.EditTasklist.displayOrder == null ? '' : this.EditTasklist.displayOrder
      this.SelectedFilters = []
      this.EditTasklist.filters.forEach(ele => {
        const filterIndex = this.TasklistFiltersRawData.findIndex(e => e.name === ele.filterName)
        this.SelectedFilters.push(this.TasklistFiltersRawData[filterIndex].id)
        this.TasklistFiltersRawData[filterIndex].selectedFilterValue = ele.value
      })
    },
    close () {
      this.$emit('update:TasklistDetailingComp', false)
    }
  }
}
</script>

<style scoped>

.SubmitCancelButton {
  min-width: 295px !important;
  margin-left: auto;
}
.SubmitCancelButton > div {
  display: inline-block;
  width: fit-content;
}
.SubmitCancelButton > div > Button {
  width: fit-content;
  height: 36px;
  padding: 0px 16px;
  margin-left: 5px;
}
.nonScrollable {
  min-height: 80px;
  max-height: 80px;
}
.scrollable {
  overflow: auto;
  max-height: 230px;
  min-height: 230px;
}
.fillHeight {
  max-height: 460px;
}
.filterHeading {
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  margin: 30px 0px;
}
.col {
  min-height: 80px;
  max-height: 80px;
  min-width: 220px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
}
.col > * {
  position: static !important;
  left: 0px !important;
  font-weight: 400 !important;
  margin: 0px 0px !important;
}
.col > label {
  height: 20px;
  font-size: 12px;
  line-height: 167%;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}
.col > .v-select {
  width: 200px;
  height: 16px;
  font-size: 16px;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.25);
}
.col > .v-text-field {
  padding-top: 0px;
}
.nowrap {
  white-space: nowrap;
  min-width: 140px !important;
  max-width: 140px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
