import { ApiClient } from "./api"
import Vue from 'vue'
import Toasted from 'vue-toasted'
import { validateTasklistInputData } from '../../utils/tasklistConstants'
Vue.use(Toasted)

export async function getTaskListForDeploymentCode (deploymentCode) {
  const responseData = await ApiClient.get('', '', null, '/v1/taskList/deployment/' + deploymentCode, 'registryENV')
  if (responseData.data.success === true) {
    return responseData.data.data
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
  }
}

export async function addTaskList (submitObj) {
  if (!validateInput(submitObj)) {
    return false
  }
  submitObj.taskListItem.displayColumns = submitObj.taskListItem.displayColumns.join()
  const responseData = await ApiClient.post('', '', submitObj, '/v1/taskList', 'registryENV')
  if (responseData.data.success === true) {
    Vue.toasted.success('Tasklist Created successfully', { duration: 2000 })
    return true
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
    return false
  }
}
function validateInput (submitObj) {
  const inValidMessage = validateTasklistInputData(submitObj)
  if (inValidMessage === 'IsValid') {
    return true
  }
  Vue.toasted.error(inValidMessage, { duration: 2000 })
  return false
}
export async function updateTaskList (submitObj, id) {
  if (!validateInput(submitObj)) {
    return false
  }
  submitObj.taskListItem.displayColumns = submitObj.taskListItem.displayColumns.join()
  const responseData = await ApiClient.put('', '', submitObj, '/v1/taskList/id/' + id, 'registryENV')
  if (responseData.data.success === true) {
    Vue.toasted.success('Tasklist Updated successfully', { duration: 2000 })
    return true
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
    return false
  }
}

export async function deleteTaskList (id) {
  const responseData = await ApiClient.delete('', '', null, '/v1/taskList/id/' + id, 'registryENV')
  if (responseData.data.success === true) {
    Vue.toasted.success('Tasklist Deleted successfully', { duration: 2000 })
    return responseData.data
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
  }
}

export async function getAllTaskList () {
  const responseData = await ApiClient.get('', '', null, '/v1/taskList', 'registryENV')
  if (responseData.data.success === true) {
    return responseData.data.data
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
  }
}

export async function getAllColumns () {
  const responseData = await ApiClient.get('', '', null, '/v1/tasklist/columns', 'registryENV')
  if (responseData.data.success === true) {
    return responseData.data.data
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
  }
}

export async function getAllFilters () {
  const responseData = await ApiClient.get('', '', null, '/v1/taskList/filters', 'registryENV')
  if (responseData.data.success === true) {
    return responseData.data.data
  } else {
    Vue.toasted.error(responseData.data.message, { duration: 2000 })
  }
}
